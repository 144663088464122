import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateService } from 'src/app/core/services/date.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { checkDateBefore } from 'src/app/core/validation/check-date-before';
import { Education, EducationLevel, educationLevelToName } from 'src/app/model/education';
import { ProFormData } from 'src/app/model/pro-form-data';
import { Profile } from 'src/app/model/profile';
import { ModalDeleteComponent } from 'src/app/core/components/modal-delete/modal-delete.component';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';

@Component({
  selector: 'app-education-form',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePickerDirective,
    ModalDeleteComponent,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './education-form.component.html',
})
export class EducationFormComponent implements OnInit {
  @Input()
  public id: string;

  public educationLevels: { key: string, name: string }[] = Object
    .values(EducationLevel)
    .map(educationLevel => ({
      key: EducationLevel[educationLevel],
      name: educationLevelToName(educationLevel)
    }));

  public title: string;
  public data$: Observable<ProFormData | null>;
  public errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dateService: DateService,
    private api: ProfileService) {
  }

  public ngOnInit(): void {
    const isCreate = this.id?.trim()?.toLowerCase() == 'create';
    this.title = isCreate ? $localize`Create education` : $localize`Edit education`;

    this.data$ = this.api
      .getActiveProfile()
      .pipe(
        map((profile: Profile) => {
          if (isCreate) {
            return new ProFormData(profile.id, this.createForm(null));
          } else {
            const initialValue = profile.educations.find(education => education.id === this.id);
            if (!initialValue) {
              return null;
            } else {
              return new ProFormData(profile.id, this.createForm(initialValue));
            }
          }
        })
      );
  }

  public onFormSubmit(data: ProFormData): void {
    if (data.canBeSaved) {
      this.api
        .addEducation(
          data.profileId,
          {
            id: data.getFormControlValue('id'),
            nameInstitute: data.getFormControlValue('nameInstitute'),
            educationLevel: data.getFormControlValue('educationLevel'),
            nameEducationNL: data.getFormControlValue('nlName'),
            nameEducationEN: data.getFormControlValue('enName'),
            startDate: this.dateService.toApiDate(data.getFormControlValue('startDate')),
            endDate: this.dateService.toApiDate(data.getFormControlValue('endDate')),
            graduated: data.getFormControlValue('hasGraduated')
          })
        .subscribe({
          next: (_: Education) => {
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          error: (err: HttpErrorResponse) => {
            this.errorMessage = err.message;
          }
        });
    }
  }

  private createForm(education: Education | undefined): FormGroup {
    return new FormGroup(
      {
        id: new FormControl(education?.id),
        nameInstitute: new FormControl(education?.nameInstitute, Validators.required),
        educationLevel: new FormControl(education?.educationLevel, Validators.required),
        nlName: new FormControl(education?.nameEducationNL, Validators.required),
        enName: new FormControl(education?.nameEducationEN),
        startDate: new FormControl(this.dateService.toUiDate(education?.startDate), Validators.required),
        endDate: new FormControl(this.dateService.toUiDate(education?.endDate)),
        hasGraduated: new FormControl(education?.graduated ?? true, Validators.required)
      },
      checkDateBefore(this.dateService, 'startDate', 'endDate'));
  }
}
