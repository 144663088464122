<div class="md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold mb-4">Personal details</h1>

  @if (data$ | async; as data) {
    <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data.profile, data.form)">
      <div class="flex flex-col gap-x-12 flex-1 flex-wrap md:flex-row">
        <div class="flex flex-col gap-3 items-center w-52 self-center mb-6 md:self-start md:mb-0">
          <div #dropdownContainer class="relative w-full aspect-square">
            <div class="w-full h-full">
              @if (data.profile.professional.photoUrl != null) {
                <img i18n-alt [src]="data.profile.professional.photoUrl" alt="Profile picture" class="rounded-full"/>
              } @else {
                <div class="text-gray-400">
                  <svg class="w-full h-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                  </svg>
                </div>
              }

              @if (photoIsLoading) {
                <div class="absolute z-5 top-0 opacity-50 bg-gray-500 w-full h-full rounded-full"></div>
                <div role="status" class="absolute z-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
              }
            </div>
            <div class="absolute top-0 right-0 rounded-full hover:bg-red-200 focus-within:bg-red-200" [hidden]="photoIsLoading">
              <button #dropdownBtn type="button" i18n-title data-dropdown-trigger="click" data-dropdown-delay="100" data-dropdown-offset-distance="2" data-dropdown-offset-skidding="75" title="Photo options" class="p-2 text-alfa1-brand-secondary-500">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                  <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
                </svg>
              </button>
              <div appDropdown [dropdownTrigger]="dropdownBtn" [dropdownShowTriggerOnEnter]="dropdownContainer" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
                  @if (data.profile.professional.photoUrl != null) {
                    <li>
                      <button type="button" i18n class="block px-4 py-2 w-full text-left hover:bg-red-200" (click)="deletePhoto(data.profile.id)">Delete</button>
                    </li>
                  }
                  <li>
                    <input #photoInput type="file" accept=".png,.jpg,.jpeg" hidden="true" (input)="dropdownBtn.click($event); uploadPhoto(data.profile.id, $event)"/>
                    <button type="button" i18n class="block px-4 py-2 w-full text-left hover:bg-red-200" (click)="photoInput.click()">Upload new</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          @if (photoUploadProgress != null) {
            <div class="w-full bg-gray-200 rounded-full h-1.5 mt-1 mb-2">
              <div class="bg-alfa1-brand-primary-500 h-1.5 rounded-full" [style.width]="photoUploadProgress + '%'"></div>
            </div>
          }
          @if (photoErrorMessage) {
            <p class="w-full mb-2 text-red-600">{{ photoErrorMessage }}</p>
          }
          <div>
            <input type="checkbox" name="use-photo" formControlName="usePhoto" autocomplete="off" class="input-checkbox" placeholder=" ">
            <label for="use-photo" i18n class="ms-2 text-sm font-medium text-gray-900">Photo can be used in CV</label>
          </div>
        </div>
        <div class="flex flex-col flex-1 gap-4">
          <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
              <label for="first-name" i18n class="block mb-2 text-sm font-semibold text-gray-900">First name*</label>
              <input type="text" id="first-name" autocomplete="given-name" formControlName="firstName" class="input-txt" placeholder="" required />
              
              @if (data.form.get('firstName'); as field) {
                @if (field.invalid && (field.dirty || field.touched)) {
                  @if (field.hasError('required')) {
                    <p i18n class="mt-2 text-sm text-red-600">The first name is required.</p>
                  } @else if (field.hasError('minlength')) {
                    <p i18n class="mt-2 text-sm text-red-600">The first name needs to be at least 2 characters long.</p>
                  }
                }
              }
            </div>
            <div class="flex-1">
              <label for="last-name" i18n class="block mb-2 text-sm font-semibold text-gray-900">Last name*</label>
              <input type="text" id="last-name" autocomplete="family-name" formControlName="lastName" class="input-txt" placeholder="" required />
              @if (data.form.get('lastName'); as field) {
                @if (field.invalid && (field.dirty || field.touched)) {
                  @if (field.hasError('required')) {
                    <p i18n class="mt-2 text-sm text-red-600">The last name is required.</p>
                  } @else if (field.hasError('minlength')) {
                    <p i18n class="mt-2 text-sm text-red-600">The last name needs to be at least 2 characters long.</p>
                  }
                }
              }
            </div>
          </div>
          <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
              <label for="city-of-residence" i18n class="block mb-2 text-sm font-semibold text-gray-900">City of residence</label>
              <input type="text" id="city-of-residence" autocomplete="address-level2" formControlName="placeOfResidence" class="input-txt" placeholder="" />
            </div>
            <div class="flex-1">
              <label for="date-of-birth" i18n class="block mb-2 text-sm font-semibold text-gray-900">Date of birth</label>
              <input type="text" appDatePicker id="date-of-birth" autocomplete="bday" formControlName="dateOfBirth" class="input-txt" placeholder="" />
            </div>
          </div>
          <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
              <label for="title" i18n class="block mb-2 text-sm font-semibold text-gray-900">Title*</label>
              <input type="text" id="title" autocomplete="organization-title" formControlName="title" class="input-txt" placeholder="" />
              @if (data.form.get('title'); as field) {
                @if (field.invalid && (field.dirty || field.touched)) {
                  @if (field.hasError('required')) {
                    <p i18n class="mt-2 text-sm text-red-600">The title is required.</p>
                  }
                }
              }
            </div>
            <div class="flex-1">
              <label for="email" i18n class="block mb-2 text-sm font-semibold text-gray-900">Email</label>
              <input type="email" id="email" autocomplete="email" formControlName="emailAddress" class="input-txt" placeholder="" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-4 md:items-end md:mt-0">
        @if (errorMessage) {
          <p class="mb-2 text-red-600">{{ errorMessage }}</p>
        }
        <button type="submit" i18n [disabled]="data.form.invalid || !data.form.dirty" class="btn-primary">Save</button>
      </div>
    </form>
  } @else {
    <div role="status" class="space-y-3.5 animate-pulse">
      <div class="flex flex-col gap-x-12 flex-1 flex-wrap md:flex-row">
        <div class="flex flex-col gap-3 items-center mb-6 w-52 self-center md:self-start">
          <div class="relative w-full aspect-square">
            <svg class="w-full h-full text-gray-300 dark:text-gray-700 me-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
            </svg>
          </div>
          <div class="flex items-center w-full">
              <div class="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-52"></div>
          </div>
        </div>
        <div class="grid gap-6 mb-6 flex-1 md:grid-cols-2">
          @for (_ of [].constructor(6); track $index) {
            <div class="flex flex-col">
              <div class="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-28 mb-2"></div>
              <div class="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-60"></div>
            </div>
          }
        </div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  }
</div>