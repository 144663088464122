import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListService } from 'src/app/core/services/list.service';
import { Assignment } from 'src/app/model/assignment';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { Profile } from 'src/app/model/profile';

@Injectable()
export class AssignmentListService extends ListService<Assignment> {
  public listName = $localize `professional experiences`;

  protected getListItems(profile: Profile): ListItem<Assignment>[] {
    return profile.assignments.map(assignment => ({
      id: assignment.id,
      title: `${assignment.organisationNL} - ${assignment.roleNL}`,
      subTitle: '',
      originalItem: assignment
    }));
  }
  
  protected updateProfile(profile: Profile, elements: Assignment[]): void {
    profile.assignments = elements;
  }

  public deleteElement(profileId: string, elementId: string): Observable<void> {
    return this.profileService.deleteAssignment(profileId, elementId);
  }
}
