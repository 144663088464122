<div class="md:max-w-screen-lg md:mx-auto">
  <h1 i18n class="text-xl font-bold mb-4">Download CV</h1>

  @if (data$ | async; as data) {
    <div class="flex flex-col gap-4">
      <div>
        <label for="profile" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Profile</span>*</label>
        <select id="profile" class="input-txt" required (change)="onProfileSelectionChange($event.target.value)">
          @for (user of data.users; track user.id) {
            <option [value]="user.profileId" [selected]="user.profileId === data.selectedProfile?.id">{{ user.name }} ({{ user.company | lowercase }})</option>
          }
        </select>
      </div>
      <form [formGroup]="data.formData.form" (ngSubmit)="generateCV(data)" class="flex flex-col gap-4">
        <div>
            <label for="language" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Language</span>*</label>
            <select id="language" class="input-txt" required formControlName="language">
              @for (language of languages; track language.key) {
                <option [value]="language.key">{{ language.value }}</option>
              }
            </select>
            
            @if (data.formData.fieldHasError('language', 'required')) {
              <p i18n class="mt-2 text-sm text-red-600">The language is required.</p>
            }
        </div>
        <div>
            <label for="template" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Template</span>*</label>
            <select id="template" class="input-txt" required formControlName="template">
              @for (template of templates; track template.key) {
                <option [value]="template.key">{{ template.name }}</option>
              }
            </select>
            
            @if (data.formData.fieldHasError('template', 'required')) {
              <p i18n class="mt-2 text-sm text-red-600">The template is required.</p>
            }
        </div>
        <div class="flex flex-col">
          <div class="flex items-center">
            <input type="checkbox" name="usePhoto" formControlName="usePhoto" autocomplete="off" class="input-checkbox">
            <label for="usePhoto" i18n class="ms-2 text-sm font-medium text-gray-900 peer-disabled:text-gray-300">Include photo in CV</label>
          </div>
          @if (!data.selectedProfile.professional.usePhoto) {
            <span i18n class="text-orange-400">The selected user has not allowed their profile photo to be used.</span>
          }
        </div>
        <div>
          <div class="flex-1 min-w-0">
            <label for="summary" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Summary</span>*</label>
            <select id="summary" class="input-txt" required formControlName="summary">
              @for (summary of data.selectedProfile?.summaries; track summary.id) {
                <option [value]="summary.id">{{ summary.name }}</option>
              }
            </select>
            
            @if (data.formData.fieldHasError('summary', 'required')) {
              <p i18n class="mt-2 text-sm text-red-600">The summary is required.</p>
            }
          </div>
        </div>
        <div class="flex flex-1 gap-4 flex-col md:flex-row">
          <div class="flex flex-col flex-1 min-w-0 md:max-h-[240.8px]">
            <label i18n class="block mb-2 text-sm font-semibold text-gray-900">Assignments</label>
            <div formArrayName="assignments" class="flex flex-col flex-1 gap-2 input-txt overflow-x-hidden overflow-y-auto">
              @for (assignment of data.selectedProfile?.assignments; track assignment.id) {
                <div class="flex flex-row gap-4">
                  <input type="checkbox" class="input-checkbox mt-[1px]" [formControlName]="$index"/>
                  <div class="flex-1 flex flex-col min-w-0">
                    <span class="text-sm font-medium text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap">{{ assignment.organisationNL }}</span>
                    <span class="text-sm font-normal text-gray-600 overflow-hidden text-ellipsis whitespace-nowrap">{{ assignment.roleNL }}</span>
                  </div>
                  <span class="text-sm text-gray-600 w-40">{{ assignmentToDateString(assignment) }}</span>
                </div>
              }
            </div>
          </div>
          <div class="flex flex-col flex-1 min-w-0 gap-4">
            <div>
              <label for="nrOfDetailedAssignments" i18n class="block mb-2 text-sm font-semibold text-gray-900">Maximum number of detailed assignments*</label>
              <select id="nrOfDetailedAssignments" class="input-txt" required formControlName="maxNrOfDetailedAssignments">
                @for (_ of [].constructor(data.maxNrOfDetailedAssignments + 1); track $index) {
                  <option [value]="$index">{{ $index }}</option>
                }
              </select>
            </div>
            <div>
              <label for="nrOfEducations" i18n class="block mb-2 text-sm font-semibold text-gray-900">Maximum number of educations*</label>
              <select id="nrOfEducations" class="input-txt" required formControlName="maxNrOfEducations">
                @for (_ of [].constructor((data.selectedProfile?.educations?.length ?? 0) + 1); track $index) {
                  <option [value]="$index">{{ $index }}</option>
                }
              </select>
            </div>
            <div>
              <label for="nrOfCertificates" i18n class="block mb-2 text-sm font-semibold text-gray-900">Maximum number of certificates*</label>
              <select id="nrOfCertificates" class="input-txt" required formControlName="maxNrOfCertificates">
                @for (_ of [].constructor((data.selectedProfile?.certificates?.length ?? 0) + 1); track $index) {
                  <option [value]="$index">{{ $index }}</option>
                }
              </select>
            </div>
          </div>
        </div>
        @if (errorMessage) {
            <p class="mt-4 text-red-600">{{ errorMessage }}</p>
        }
        <button type="submit" [disabled]="!data.formData.form.valid || generating" class="btn-primary flex justify-center items-center gap-4">
          @if (generating) {
            <div role="status" class="ms-2">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-alfa1-brand-primary-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
            </div>
            <span i18n>Generating...</span>
          } @else {
            <span i18n>Generate</span>
          }
        </button>
      </form>
    </div>
  } @else {
    <div role="status" class="flex flex-col gap-4 animate-pulse">
        <div>
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
        <div>
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
        <div>
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
        <div>
            <div class="w-48 h-5 mb-2 bg-gray-300 rounded-xl"></div>
        </div>
        <div>
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
        <div class="flex flex-1 gap-4 flex-col md:flex-row">
          <div class="flex-1">
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
          <div class="flex-1">
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
        </div>
        <div class="flex flex-1 gap-4 flex-col md:flex-row">
          <div class="flex-1">
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
          <div class="flex-1">
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
        </div>
        <span class="sr-only">Loading...</span>
    </div>
  }
</div>