import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { DateService } from "../services/date.service";

export function checkDateBefore(dateService: DateService, beforeDate: string, afterDate: string): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormGroup) {
            const beforeDateControl = control.get(beforeDate);
            const afterDateControl = control.get(afterDate);
      
            const comparison = dateService.compareUiDate(beforeDateControl.value, afterDateControl.value);
            if (comparison >= 0 && (beforeDateControl.dirty || afterDateControl.dirty)) {
              return { 'dateBefore': `The date ${beforeDateControl.value} must be before date ${afterDateControl.value}.` }
            }
        } else {
            console.error('This validator can only be applied to form groups.');
            return null;
        }
    };
}
