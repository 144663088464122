import { LowerCasePipe } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-delete-user-form',
  standalone: true,
  imports: [
    LowerCasePipe
  ],
  templateUrl: './delete-user-form.component.html'
})
export class DeleteUserFormComponent {
  protected selectedUser: User | null = null;
  protected isBusy = false;
  protected errorMessage = '';

  @Input({ required: true })
  public users: User[] = [];

  public onUserDeleted = output<User>();

  constructor(private userService: UserService) {
  }

  public onUserSelect(userId: string): void {
    if (userId) {
      const userToSelect = this.users.find(user => user.id === userId);
      if (userToSelect !== undefined) {
        this.selectedUser = userToSelect;
      }
    } else {
      this.selectedUser = null;
    }
  }

  public onDelete(): void {
    if (this.selectedUser !== null) {
      this.errorMessage = '';
      this.isBusy = true;

      this.userService
        .delete(this.selectedUser.id)
        .subscribe({
          next: () => {
            const user = this.selectedUser;
            this.selectedUser = null;

            this.onUserDeleted.emit(user);
          },
          error: (err) => this.errorMessage = err.message,
          complete: () => this.isBusy = false
        });
    }
  }
}
