import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ProFormData } from 'src/app/model/pro-form-data';
import { Profile } from 'src/app/model/profile';
import { Tool, ToolLevel, toolLevelToName, ToolType, toolTypeToName } from 'src/app/model/tool';
import { ModalDeleteComponent } from 'src/app/core/components/modal-delete/modal-delete.component';

@Component({
  selector: 'app-tool-form',
  standalone: true,
  imports: [
    AsyncPipe,
    ModalDeleteComponent,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './tool-form.component.html'
})
export class ToolFormComponent implements OnInit {
  @Input()
  public id: string;

  public toolTypes: { key: string, name: string }[] = Object.values(ToolType).map(toolType => ({ key: ToolType[toolType], name: toolTypeToName(toolType) }));
  public toolLevels: { key: string, name: string }[] = Object.values(ToolLevel).map(toolLevel => ({ key: ToolLevel[toolLevel], name: toolLevelToName(toolLevel) }));

  public title: string;
  public data$: Observable<ProFormData | null>;
  public errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ProfileService) {
  }

  public ngOnInit(): void {
    const isCreate = this.id?.trim()?.toLowerCase() == 'create';
    this.title = isCreate ? $localize`Create tool or technique` : $localize`Edit tool or technique`;

    this.data$ = this.api
      .getActiveProfile()
      .pipe(
        map((profile: Profile) => {
          if (isCreate) {
            return new ProFormData(profile.id, this.createForm(null));
          } else {
            const initialValue = profile.tools.find(tool => tool.id === this.id);
            if (!initialValue) {
              return null;
            } else {
              return new ProFormData(profile.id, this.createForm(initialValue));
            }
          }
        })
      );
  }

  public onFormSubmit(data: ProFormData): void {
    if (data.canBeSaved) {
      this.api
        .addTool(
          data.profileId,
          {
            id: data.getFormControlValue('id'),
            name: data.getFormControlValue('name'),
            toolType: data.getFormControlValue('toolType'),
            level: data.getFormControlValue('toolLevel'),
          })
        .subscribe({
          next: (_: Tool) => {
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          error: (err: HttpErrorResponse) => {
            this.errorMessage = err.message;
          }
        });
    }
  }

  private createForm(tool: Tool | undefined): FormGroup {
    return new FormGroup({
      id: new FormControl(tool?.id),
      name: new FormControl(tool?.name, Validators.required),
      toolType: new FormControl(tool?.toolType, Validators.required),
      toolLevel: new FormControl(tool?.level, Validators.required),
    });
  }
}
