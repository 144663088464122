import { Component, OnInit } from '@angular/core';
import { Profile } from "../model/profile";
import { ProfileService } from "../core/services/profile.service";
import { CvService } from "../core/services/cv.service";
import { CurriculumVitaeSpecification } from "../model/curriculumVitaeSpecification";
import { UserService } from "../core/services/user.service";
import { User } from "../model/user";
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { Companies, Company } from '../model/company.model';
import { map, switchMap } from 'rxjs/operators';
import { ProFormData } from '../model/pro-form-data';
import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { DateService } from '../core/services/date.service';
import { Assignment } from '../model/assignment';

@Component({
  selector: 'app-download',
  standalone: true,
  imports: [
    AsyncPipe,
    LowerCasePipe,
    ReactiveFormsModule
],
  templateUrl: './download.component.html'
})
export class DownloadComponent implements OnInit {
  public static readonly MAX_NR_OF_DETAILED_ASSIGNMENTS = 5;

  public languages = [
    { key: 'DUTCH', value: $localize`Dutch` },
    { key: 'ENGLISH', value: $localize`English` }
  ];

  public templates = Companies.getAllCompanies();

  public data$: Observable<Data>;
  public errorMessage: string;
  public generating = false;

  constructor(
    private dateService: DateService,
    private userService: UserService,
    private profileService: ProfileService,
    private cvService: CvService) {
  }

  public ngOnInit(): void {
    this.data$ = combineLatest([this.getAvailableUsers(), this.profileService.getActiveProfile()]).pipe(
      map(([availableUsers, activeProfile]) => ({
        users: availableUsers,
        maxNrOfDetailedAssignments: Math.min(activeProfile.assignments.length, DownloadComponent.MAX_NR_OF_DETAILED_ASSIGNMENTS),
        selectedProfile: activeProfile,
        formData: new ProFormData(activeProfile.id, this.createForm(activeProfile))
      }))
    );
  }

  public onProfileSelectionChange(profileId: string): void {
    if (profileId) {
      this.profileService.setActiveProfile(profileId);
    }
  }

  public generateCV(data: Data): void {
    this.errorMessage = null;
    this.generating = true;

    const assignmentsValue = data.formData.getFormControlArray('assignments').value as boolean[];
    const assignmentIds = assignmentsValue
      .map((selected, idx) => ({
        assignmentId: data.selectedProfile.assignments[idx].id,
        selected: selected
      }))
      .filter(item => item.selected)
      .map(item => item.assignmentId);

    const maxNrOfDetailedAssignments = Math.min(assignmentIds.length, data.formData.getFormControlValue('maxNrOfDetailedAssignments'));

    const cv: CurriculumVitaeSpecification = {
      profileId: data.formData.profileId,
      language: data.formData.getFormControlValue('language'),
      summaryId: data.formData.getFormControlValue('summary'),
      company: data.formData.getFormControlValue('template'),
      usePhoto: data.formData.getFormControlValue('usePhoto'),
      selectAssignments: assignmentIds,
      maxEducation: parseInt(data.formData.getFormControlValue('maxNrOfEducations')),
      maxCertificates: parseInt(data.formData.getFormControlValue('maxNrOfCertificates')),
      maxAssignmentsDetail: maxNrOfDetailedAssignments,
      maxAssignmentsSummary: assignmentIds.length - maxNrOfDetailedAssignments
    };

    this.cvService
      .generateCV(cv)
      .subscribe({
        next: blob => {
          this.downloadFile(data.selectedProfile, blob);
        },
        error: err => {
          console.log(err)
          this.errorMessage = $localize`Something went wrong while downloading the CV. Please contact the system administrator. ${err.message}`;
        },
        complete: () => {
          this.generating = false;
        }
      });
  }

  private createForm(selectedProfile: Profile): FormGroup {
    return new FormGroup({
      language: new FormControl(this.languages[0].key, Validators.required),
      template: new FormControl(selectedProfile.professional.company, Validators.required),
      usePhoto: new FormControl({
        value: selectedProfile.professional.usePhoto,
        disabled: !selectedProfile.professional.usePhoto
      }),
      summary: new FormControl(selectedProfile.summaries.length > 0 ? selectedProfile.summaries[0].id : null, Validators.required),
      assignments: new FormArray(selectedProfile.assignments.map(_ => new FormControl(true))),
      maxNrOfDetailedAssignments: new FormControl(Math.min(selectedProfile.assignments.length, DownloadComponent.MAX_NR_OF_DETAILED_ASSIGNMENTS), Validators.required),
      maxNrOfEducations: new FormControl(selectedProfile.educations.length, Validators.required),
      maxNrOfCertificates: new FormControl(selectedProfile.certificates.length, Validators.required)
    });
  }

  protected assignmentToDateString(assignment: Assignment): string {
    const endDateString = assignment.endDate
      ? this.dateService.toUiDate(assignment.endDate)
      : $localize`:Current moment in time:Present`;

    return `${this.dateService.toUiDate(assignment.startDate)} - ${endDateString}`;
  }

  private getAvailableUsers(): Observable<User[]> {
    return this.userService.getCurrent().pipe(
      switchMap(user => {
        if (!user.isSales) {
          return of(([user]));
        } else {
          return combineLatest([this.userService.getAll(), this.profileService.getAvailableProfileIds()]).pipe(
            map(([users, profileIds]) => {
              return users
                .filter(user => profileIds.indexOf(user.profileId) > -1)
                .sort((user1, user2) => user1.name.localeCompare(user2.name))
            })
          );
        }
      })
    );
  }

  private getCurrentTimestamp(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}${month}${day}-${hours}${minutes}`;
  }

  private downloadFile(selectedProfile: Profile, blob: Blob): void {
    const url = window.URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = url;
    link.download = `CV ${selectedProfile.professional.fullName} - ${this.getCurrentTimestamp()}.docx`;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }
}

interface Data {
  users: User[];
  maxNrOfDetailedAssignments: number,
  selectedProfile: Profile | null;
  formData: ProFormData
}