import { Component, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Companies, Company } from 'src/app/model/company.model';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-create-user-form',
  standalone: true,
  imports: [
    ReactiveFormsModule
],
  templateUrl: './create-user-form.component.html'
})
export class CreateUserFormComponent {
  protected companies = Companies.getAllCompanies();
  protected form = this.createForm();

  protected isBusy = false;
  public errorMessage = '';
  public onUserCreated = output<User>();

  constructor(private userService: UserService) {
  }

  public onSubmit() {
    if (this.form.dirty && this.form.valid) {
      this.isBusy = true;
      this.errorMessage = '';

      this.userService
        .create(
          this.form.value['name'],
          this.form.value['email'],
          this.form.value['company']
        )
        .subscribe({
          next: (user: User) => {
            this.form = this.createForm();
            this.onUserCreated.emit(user);
          },
          error: (err) => this.errorMessage = err.message,
          complete: () => this.isBusy = false
        });
    }
  }

  private createForm(): FormGroup {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      company: new FormControl(null, Validators.required)
    });
  }
}
