import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DndDraggableDirective, DndDropEvent, DndDropzoneDirective, DndHandleDirective } from 'ngx-drag-drop';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateService } from 'src/app/core/services/date.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { checkDateBefore } from 'src/app/core/validation/check-date-before';
import { Assignment } from 'src/app/model/assignment';
import { ProFormData } from 'src/app/model/pro-form-data';
import { Profile } from 'src/app/model/profile';
import { ModalDeleteComponent } from 'src/app/core/components/modal-delete/modal-delete.component';
import { AutogrowContainerDirective } from 'src/app/core/directives/autogrow-container.directive';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';

@Component({
  selector: 'app-assignment-form',
  standalone: true,
  imports: [
    AsyncPipe,
    AutogrowContainerDirective,
    DatePickerDirective,
    DndDraggableDirective,
    DndDropzoneDirective,
    DndHandleDirective,
    ModalDeleteComponent,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './assignment-form.component.html'
})
export class AssignmentFormComponent implements OnInit {
  @Input()
  public id: string;

  public title: string;
  public data$: Observable<ProFormData | null>;
  public errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dateService: DateService,
    private api: ProfileService) {
  }

  public ngOnInit(): void {
    const isCreate = this.id?.trim()?.toLowerCase() == 'create';
    this.title = isCreate ? $localize`Create professional experience` : $localize`Edit professional experience`;

    this.data$ = this.api
      .getActiveProfile()
      .pipe(
        map((profile: Profile) => {
          if (isCreate) {
            return new ProFormData(profile.id, this.createForm(null));
          } else {
            const initialValue = profile.assignments.find(assignment => assignment.id === this.id);
            if (!initialValue) {
              return null;
            } else {
              return new ProFormData(profile.id, this.createForm(initialValue));
            }
          }
        })
      );
  }

  public onFormSubmit(data: ProFormData): void {
    if (data.canBeSaved) {
      this.api
        .addAssignment(
          data.profileId,
          {
            id: data.getFormControlValue('id'),
            organisationNL: data.getFormControlValue('nlOrganisation'),
            roleNL: data.getFormControlValue('nlRole'),
            descriptionNL: data.getFormControlValue('nlDescription'),
            organisationEN: data.getFormControlValue('enOrganisation') ?? '',
            roleEN: data.getFormControlValue('enRole') ?? '',
            descriptionEN: data.getFormControlValue('enDescription') ?? '',
            startDate: this.dateService.toApiDate(data.getFormControlValue('startDate')),
            endDate: this.dateService.toApiDate(data.getFormControlValue('endDate')),
            tools: data.getFormControlArrayAsStringArray('tools')
          })
        .subscribe({
          next: (_: Assignment) => {
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          error: (err: HttpErrorResponse) => {
            this.errorMessage = err.message;
          }
        });
    }
  }

  public onDropTool(data: ProFormData, event: DndDropEvent) {
    if (event.dropEffect === 'move') {
      const currentIdx = event.data as number;
      const newIdx = event.index;

      data.moveFormControlInArray('tools', currentIdx, newIdx);
    }
  }

  public addTool(data: ProFormData): void {
    data.addEmptyFormControlToArray('tools');
  }

  public deleteTool(data: ProFormData, idx: number): void {
    data.deleteFormControlFromArray('tools', idx);
  }

  private createForm(assignment: Assignment | undefined): FormGroup {
    const tools = (assignment?.tools ?? [])
      .map(tool => new FormControl(tool));

    return new FormGroup(
      {
        id: new FormControl(assignment?.id),
        nlOrganisation: new FormControl(assignment?.organisationNL, Validators.required),
        nlRole: new FormControl(assignment?.roleNL, Validators.required),
        nlDescription: new FormControl(assignment?.descriptionNL, Validators.required),
        enOrganisation: new FormControl(assignment?.organisationEN),
        enRole: new FormControl(assignment?.roleEN),
        enDescription: new FormControl(assignment?.descriptionEN),
        startDate: new FormControl(this.dateService.toUiDate(assignment?.startDate), Validators.required),
        endDate: new FormControl(this.dateService.toUiDate(assignment?.endDate)),
        tools: new FormArray(tools)
      },
      checkDateBefore(this.dateService, 'startDate', 'endDate'));
  }
}
