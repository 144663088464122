import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProfileService } from "../../../core/services/profile.service";
import { Profile } from "../../../model/profile";
import { Group } from "../../../model/group";
import { AsyncPipe, DatePipe } from "@angular/common";
import { Professional } from 'src/app/model/professional';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';
import { AutogrowDirective } from 'src/app/core/directives/autogrow.directive';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { DropdownDirective } from 'src/app/core/directives/dropdown.directive';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-personal-details',
  standalone: true,
  templateUrl: './personal-details.component.html',
  imports: [
    AutogrowDirective,
    AsyncPipe,
    DatePipe,
    DatePickerDirective,
    DropdownDirective,
    ReactiveFormsModule
  ]
})
export class PersonalDetailsComponent implements OnInit {
  private static readonly MAX_PHOTO_SIZE_MB = 5;

  public profileChange$ = new ReplaySubject<Profile>(1);
  public data$: Observable<{ profile: Profile, form: FormGroup }>;
  public errorMessage = '';

  public photoIsLoading = false;
  public photoErrorMessage = '';
  public photoUploadProgress: number | undefined;

  constructor(
    private dateService: DateService,
    private profileService: ProfileService) {
  }

  public ngOnInit(): void {
    this.data$ = this.profileChange$.pipe(
      map(profile => ({ profile: profile, form: this.createForm(profile.professional) }))
    );

    this.profileService.getActiveProfile().subscribe({
      next: (profile) => this.profileChange$.next(profile),
      error: (err: HttpErrorResponse) => this.errorMessage = err.message
    });
  }

  public deletePhoto(profileId: string): void {
    this.photoErrorMessage = '';
    this.photoIsLoading = true;

    this.profileService
      .deleteCurrentPhoto(profileId)
      .subscribe({
        next: (profile) => this.profileChange$.next(profile),
        error: (err: HttpErrorResponse) => this.photoErrorMessage = err.message,
        complete: () => this.photoIsLoading = false
    });
  }

  public uploadPhoto(profileId: string, e: Event): void {
    const input = e.target as HTMLInputElement;
    if (input.files.length > 1) {
      this.photoErrorMessage = 'Only one photo can be uploaded.';
    } else if (input.files.length == 0) {
      this.photoErrorMessage = 'Select a photo to upload.';
    } else {
      const photo = input.files[0];

      if (photo.size > PersonalDetailsComponent.MAX_PHOTO_SIZE_MB * 1024 * 1024) {
        this.photoErrorMessage = `The photo cannot be larger than ${PersonalDetailsComponent.MAX_PHOTO_SIZE_MB}MB.`;
      } else {
        this.photoErrorMessage = '';
        this.photoIsLoading = true;
        this.photoUploadProgress = 0;

        this.profileService.uploadPhoto(profileId, photo).subscribe({
          next: (event: HttpEvent<Profile>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.photoUploadProgress = 0;
                break;
              case HttpEventType.UploadProgress:
                this.photoUploadProgress = event.loaded / event.total;
                break;
              case HttpEventType.Response:
                this.profileChange$.next(event.body);
                break;
            }
          },
          error: (err: HttpErrorResponse) => this.photoErrorMessage = err.message,
          complete: () => {
            this.photoUploadProgress = null;
            this.photoIsLoading = false;
          }
        });
      }
    }
  }

  public onFormSubmit(profile: Profile, form: FormGroup): void {
    if (form.valid && form.dirty) {
      const newProfile = {...profile};
      newProfile.professional.firstName = form.value['firstName'];
      newProfile.professional.lastName = form.value['lastName'];
      newProfile.professional.dateOfBirth = this.dateService.toApiDate(form.value['dateOfBirth']);
      newProfile.professional.placeOfResidence = form.value['placeOfResidence'];
      newProfile.professional.title = form.value['title'];
      newProfile.professional.emailAddress = form.value['emailAddress'];
      newProfile.professional.usePhoto = form.value['usePhoto'];
      
      this.profileService.updateProfile(newProfile).subscribe({
        next: profile => {
          this.profileChange$.next(profile);
          this.errorMessage = '';
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err.message;
        }
      });
    }
  }

  private createForm(professional: Professional): FormGroup {
    const dateOfBirth = professional.dateOfBirth
      ? this.dateService.toUiDate(professional.dateOfBirth)
      : '';

    return new FormGroup({
      firstName: new FormControl(professional.firstName, [ Validators.required, Validators.minLength(2) ]),
      lastName: new FormControl(professional.lastName, [ Validators.required, Validators.minLength(2) ]),
      title: new FormControl(professional.title, Validators.required),
      dateOfBirth: new FormControl(dateOfBirth, Validators.required),
      placeOfResidence: new FormControl(professional.placeOfResidence, Validators.required),
      emailAddress: new FormControl(professional.emailAddress),
      usePhoto: new FormControl(professional.usePhoto)
    });
  }
}
