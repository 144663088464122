<div class="md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold mb-4">{{ title }}</h1>

  @if (data$ | async; as data) {
    @if (data == null) {
      <p i18n class="text-red-600">Unknown professional experience</p>
    } @else {
      <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
        <div #isSizeMd class="hidden collapse md:visible"></div>
        <div appAutogrowContainer [autogrowGroupedIfVisible]="isSizeMd" class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
                <div class="flex flex-col gap-4 rounded p-4 bg-gray-50 border border-gray-100">
                  <div>
                    <label for="nlOrg" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Organisation</span>*</label>
                    <input id="nlOrg" formControlName="nlOrganisation" type="text" autocomplete="organization" class="input-txt bg-white" placeholder="" required/>
                    
                    @if (data.fieldHasError('nlOrganisation', 'required')) {
                      <p i18n class="mt-2 text-sm text-red-600">The dutch organisation is required.</p>
                    }
                  </div>
                  <div>
                    <label for="nlRole" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Role</span>*</label>
                    <input id="nlRole" formControlName="nlRole" type="text" autocomplete="organization-title" class="input-txt bg-white" placeholder="" required/>
                    
                    @if (data.fieldHasError('nlRole', 'required')) {
                      <p i18n class="mt-2 text-sm text-red-600">The dutch role is required.</p>
                    }
                  </div>
                  <div>
                    <label for="nlDesc" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Description</span>*</label>
                    <textarea id="nlDesc" formControlName="nlDescription" data-autogrow rows="4" class="input-txt bg-white" placeholder="" required></textarea>
                    
                    @if (data.fieldHasError('nlDescription', 'required')) {
                      <p i18n class="mt-2 text-sm text-red-600">The dutch description is required.</p>
                    }
                  </div>
                </div>
            </div>
            <div class="border border-gray-300 rounded my-3 hidden md:block"></div>
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
                <div class="flex flex-col gap-4 rounded p-4 bg-gray-50 border border-gray-100">
                  <div>
                    <label for="enOrg" i18n class="block mb-2 text-sm font-semibold text-gray-900">Organisation</label>
                    <input id="enOrg" formControlName="enOrganisation" type="text" autocomplete="organization" class="input-txt bg-white" placeholder=""/>
                  </div>
                  <div>
                    <label for="enRole" i18n class="block mb-2 text-sm font-semibold text-gray-900">Role</label>
                    <input id="enRole" formControlName="enRole" type="text" autocomplete="organization-title" class="input-txt bg-white" placeholder=""/>
                  </div>
                  <div>
                    <label for="enDesc" i18n class="block mb-2 text-sm font-semibold text-gray-900">Description</label>
                    <textarea id="enDesc" formControlName="enDescription" data-autogrow rows="4" class="input-txt bg-white" placeholder=""></textarea>
                  </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-4 mt-4 md:flex-row">
          <div class="flex-1">
            <label for="startDate" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Start date</span>*</label>
            <input id="startDate" formControlName="startDate" type="text" appDatePicker autocomplete="off" class="input-txt" placeholder="" required/>
            
            @if (data.fieldHasError('startDate', 'required')) {
              <p i18n class="mt-2 text-sm text-red-600">The start date is required.</p>
            }
          </div>
          <div class="flex-1">
            <label for="endDate" i18n class="block mb-2 text-sm font-semibold text-gray-900">End date</label>
            <input id="endDate" formControlName="endDate" type="text" appDatePicker autocomplete="off" class="input-txt" placeholder=""/>
            
            @if (data.formHasError('dateBefore')) {
              <p i18n class="mt-2 text-sm text-red-600">The start date must be before the end date.</p>
            }
          </div>
        </div>
        <div class="flex flex-col gap-4 mt-4 md:flex-row">
          <div formArrayName="tools" class="flex-1">
            <div class="flex flex-row mb-3 items-center">
              <h2 i18n class="text-sm font-semibold flex-1">Tools</h2>      
              <button i18n-title type="button" title="Add new tool" tabindex="-1" (click)="addTool(data)" class="text-gray-500 hover:text-gray-700">
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
              </button>
            </div>
            <div class="grid mb-6 flex-1 gap-2 grid-cols-1 md:gap-4 md:grid-cols-2" dndDropzone (dndDrop)="onDropTool(data, $event)">
              <div class="py-2 bg-gray-50 border border-gray-200 rounded-lg" dndPlaceholderRef>
                <span class="font-medium collapse">Placeholder</span>
              </div>
              @for (tool of data.getFormControlArray('tools').controls; let i = $index; track tool) {
                <div class="relative z-0 w-full group flex items-center" [dndDraggable]="$index" dndEffectAllowed="move" dndDragImageRef>
                  <div class="absolute end-0 pr-2 flex flex-row gap-1">
                    <button i18n-title type="button" title="Delete tool" (click)="modalDelete.open($index)" tabindex="-1" class="text-gray-300 hover:text-gray-600">
                      <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                      </svg>
                    </button>
                    <div dndHandle>
                      <button i18n-title type="button" title="Move tool" data-drag-trigger tabindex="-1" class="text-gray-300 hover:text-gray-600 cursor-move">
                        <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" x="128" y="128" role="img" style="display:inline-block;vertical-align:middle" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M9.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L10.5 3.707V6.5a.5.5 0 0 1-1 0V3.707L8.354 4.854a.5.5 0 1 1-.708-.708l2-2ZM10 12a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm0-1a1 1 0 1 1 0-2a1 1 0 0 1 0 2Zm5.854 1.354l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.708.708L16.293 9.5H13.5a.5.5 0 0 0 0 1h2.793l-1.147 1.146a.5.5 0 0 0 .708.708Zm-5.5 5.5l2-2a.5.5 0 0 0-.708-.708L10.5 16.293V13.5a.5.5 0 0 0-1 0v2.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0Zm-8.208-7.5a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L3.707 9.5H6.5a.5.5 0 0 1 0 1H3.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2Z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <input type="text" name="tool-{{i}}" [formControlName]="i" autocomplete="off" class="input-txt pe-14" placeholder=" ">
                </div>
              }
            </div>
          </div>
        </div>
        @if (errorMessage) {
            <p class="mt-4 text-red-600">{{ errorMessage }}</p>
        }
        <div class="mt-4 flex gap-4 flex-col md:flex-row-reverse">
            <button i18n type="submit" [disabled]="!data.canBeSaved" class="btn-primary">Save</button>
            <a i18n routerLink="../" class="btn-secondary">Cancel</a>
        </div>
        <app-modal-delete #modalDelete i18n-modalDeleteText modalDeleteText="Are you sure you want to delete this tool?" (onDelete)="deleteTool(data, $event)"></app-modal-delete>
      </form>
    }
  } @else {
    <div role="status" class="flex flex-col gap-4 animate-pulse">
      <div class="flex flex-col gap-4 md:flex-row">
        <div class="flex-1">
          <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
          <div class="flex flex-col gap-4 rounded p-4 bg-gray-50 border border-gray-100">
            <div>
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            </div>
            <div>
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            </div>
            <div>
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[102px] bg-gray-300 rounded-xl"></div>
            </div>
          </div>
        </div>
        <div class="border border-gray-300 rounded my-3 hidden md:block"></div>
        <div class="flex-1">
          <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
          <div class="flex flex-col gap-4 rounded p-4 bg-gray-50 border border-gray-100">
            <div>
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            </div>
            <div>
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            </div>
            <div>
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[102px] bg-gray-300 rounded-xl"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4 mt-4 md:flex-row">
        <div class="flex-1">
          <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
          <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
        <div class="flex-1">
          <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
          <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
      </div>
      <div class="flex flex-col gap-4 mt-4 md:flex-row">
        <div class="flex-1">
          <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
          <div class="grid mb-6 flex-1 gap-2 grid-cols-1 md:gap-4 md:grid-cols-2">
            @for (_ of [].constructor(4); track $index) {
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            }
          </div>
        </div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  }
</div>
