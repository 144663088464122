export enum Company {
    GROUP9 = 'group9',
    HAYSTAQ = 'haystaq',
    MSTACK = 'mstack',
    BRIGHTR = 'bright-r',
    ALFAONE = 'alfa1',
    BLUPOINT = 'blupoint',
    ADAPTIF = 'adaptif',
    N_ABLED = 'n-abled',
    MERAPAR = 'merapar',
    UNKNOWN = 'unknown'
}

export namespace Companies {
    export function compare(company1: Company, company2: Company): number {
        if (!company1 && !company2) {
            return 0;
        } else if (!company1) {
            return -1;
        } else if (!company2) {
            return 1;
        } else if (company1 === company2) {
            return 0;
        } else if (company1 === Company.ALFAONE) {
            return -1;
        } else if (company2 === Company.ALFAONE) {
            return 1;
        } else if (company1 < company2) {
            return -1;
        } else {
            return 1;
        }
    }

    export function getAllCompanies(): { key: string, name: string }[] {
        const exclude = [ Company.BRIGHTR, Company.UNKNOWN ];

        return Object
            .keys(Company)
            .map(keyName => ({
                key: keyName,
                name: Company[keyName]
            }))
            .filter(company => !exclude.includes(company.name))
            .sort((comp1, comp2) => compare(comp1.name, comp2.name));
    }
}
