import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateService } from 'src/app/core/services/date.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Certificate } from 'src/app/model/certificate';
import { ProFormData } from 'src/app/model/pro-form-data';
import { Profile } from 'src/app/model/profile';
import { ModalDeleteComponent } from 'src/app/core/components/modal-delete/modal-delete.component';
import { DatePickerDirective } from 'src/app/core/directives/date-picker.directive';

@Component({
  selector: 'app-certificate-form',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePickerDirective,
    ModalDeleteComponent,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './certificate-form.component.html',
})
export class CertificateFormComponent implements OnInit {
  @Input()
  public id: string;

  public title: string;
  public data$: Observable<ProFormData | null>;
  public errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dateService: DateService,
    private api: ProfileService) {
  }

  public ngOnInit(): void {
    const isCreate = this.id?.trim()?.toLowerCase() == 'create';
    this.title = isCreate ? $localize`Create training or certificate` : $localize`Edit training or certificate`;

    this.data$ = this.api
      .getActiveProfile()
      .pipe(
        map((profile: Profile) => {
          if (isCreate) {
            return new ProFormData(profile.id, this.createForm(null));
          } else {
            const initialValue = profile.certificates.find(certificate => certificate.id === this.id);
            if (!initialValue) {
              return null;
            } else {
              return new ProFormData(profile.id, this.createForm(initialValue));
            }
          }
        })
      );
  }

  public onFormSubmit(data: ProFormData): void {
    if (data.canBeSaved) {
      this.api
        .addCertificate(
          data.profileId,
          {
            id: data.getFormControlValue('id'),
            name: data.getFormControlValue('name'),
            issueDate: this.dateService.toApiDate(data.getFormControlValue('issueDate'))
          })
        .subscribe({
          next: (_: Certificate) => {
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          error: (err: HttpErrorResponse) => {
            this.errorMessage = err.message;
          }
        });
    }
  }

  private createForm(certificate: Certificate | undefined): FormGroup {
    return new FormGroup({
      id: new FormControl(certificate?.id),
      name: new FormControl(certificate?.name, Validators.required),
      issueDate: new FormControl(this.dateService.toUiDate(certificate?.issueDate), Validators.required)
    });
  }
}
