<div class="md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold mb-4">{{ title }}</h1>

  @if (data$ | async; as data) {
    @if (data == null) {
      <p i18n class="text-red-600">Unknown training or certificate</p>
    } @else {
      <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
        <div class="flex-1">
            <label for="name" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Name</span>*</label>
            <input type="text" id="name" autocomplete="off" formControlName="name" class="input-txt" placeholder="" required />
            
            @if (data.fieldHasError('name', 'required')) {
                <p i18n class="mt-2 text-sm text-red-600">The name is required.</p>
            }
        </div>
        <div class="flex-1">
            <label for="issueDate" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Issue date</span>*</label>
            <input type="text" id="issueDate" autocomplete="off" appDatePicker formControlName="issueDate" class="input-txt" placeholder="" required />
            
            @if (data.fieldHasError('issueDate', 'required')) {
                <p i18n class="mt-2 text-sm text-red-600">The issue date is required.</p>
            }
        </div>
        @if (errorMessage) {
            <p class="mt-4 text-red-600">{{ errorMessage }}</p>
        }
        <div class="mt-4 flex gap-4 flex-col md:flex-row-reverse">
            <button i18n type="submit" [disabled]="!data.canBeSaved" class="btn-primary">Save</button>
            <a i18n routerLink="../" class="btn-secondary">Cancel</a>
        </div>
      </form>
    }
  } @else {
      <div role="status" class="flex flex-col gap-4 animate-pulse">
        <div class="flex-1">
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
        <div class="flex-1">
            <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
            <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
        </div>
        <span class="sr-only">Loading...</span>
      </div>
  }
</div>
