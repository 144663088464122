import { FormArray, FormControl, FormGroup } from "@angular/forms";

export class ProFormData {
    constructor(public profileId: string, public form: FormGroup) {
    }

    public get canBeSaved() {
      return this.form.valid && this.form.dirty;
    }

    public getFormControlArray(fieldName: string): FormArray {
      return this.form.get(fieldName) as FormArray;
    }

    public getFormControl(fieldName: string): FormControl {
      return this.form.get(fieldName) as FormControl;
    }

    public getFormControlValue(fieldName: string): any {
      return this.form.value[fieldName];
    }

    public getFormControlArrayAsStringArray(fieldName: string): string[] {
      const controlArray = this.getFormControlArray(fieldName);
      const values = [];
  
      for (let i = 0; i < controlArray.length; i++) {
        if (controlArray.value[i]) {
          values.push(controlArray.value[i]);
        }
      }
  
      return values;
    }

    public addEmptyFormControlToArray(fieldName: string): void {
      const controlArray = this.getFormControlArray(fieldName);
      controlArray.push(new FormControl(''));
    }

    public moveFormControlInArray(fieldName: string, currentIdx: number, newIdx: number | null): void {
      const controlArray = this.getFormControlArray(fieldName);
      const newIdxInArray = newIdx ?? controlArray.length;

      const controlToBeMoved = controlArray.at(currentIdx);

      if (newIdxInArray < currentIdx) {
        controlArray.removeAt(currentIdx);
        controlArray.insert(newIdxInArray, controlToBeMoved);
        controlArray.markAsDirty();
      } else if (currentIdx < newIdxInArray) {
        controlArray.insert(newIdxInArray, controlToBeMoved);
        controlArray.removeAt(currentIdx);
        controlArray.markAsDirty();
      }
    }

    public deleteFormControlFromArray(fieldName: string, idx: number): void {
      const controlArray = this.getFormControlArray(fieldName);
      controlArray.removeAt(idx);
      controlArray.markAsDirty();
    }

    public fieldHasError(fieldName: string, errorCode: string): boolean {
      const field = this.form.get(fieldName);
      return field !== null
        && field.invalid
        && (field.dirty || field.touched)
        && field.hasError(errorCode);
    }

    public formHasError(errorCode: string): boolean {
      return (this.form.dirty || this.form.touched)
        && this.form.hasError(errorCode);
    }
}
