import { Directive, ElementRef, HostListener, Inject, LOCALE_ID } from '@angular/core';
import { Datepicker } from 'flowbite';
import * as moment from 'moment';

@Directive({
  selector: '[appDatePicker]',
  standalone: true
})
export class DatePickerDirective {
  private dateFormat: string;
  private datePicker: Datepicker;

  constructor(@Inject(LOCALE_ID) locale: string, private elem: ElementRef) {
    this.dateFormat = moment.localeData(locale).longDateFormat('L');

    this.datePicker = new Datepicker(
      this.elem.nativeElement,
      {
        autohide: true,
        language: locale,
        format: this.dateFormat.toLowerCase()
      });
    this.datePicker.init();
  }

  @HostListener('changeDate')
  public onDateChanged(): void {
    const e = new Event('input', { bubbles: true });
    this.elem.nativeElement.dispatchEvent(e);
  }
}
