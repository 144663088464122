import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from "rxjs";
import { UserService } from "../../services/user.service";
import { AsyncPipe } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Profile } from 'src/app/model/profile';
import { ProfileService } from 'src/app/core/services/profile.service';
import { User } from 'src/app/model/user';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  templateUrl: './nav-bar.component.html',
  imports: [
    AsyncPipe,
    RouterLink,
    RouterLinkActive,
  ]
})
export class NavBarComponent implements OnInit {
  data$: Observable<Data>;

  constructor(
    private userService: UserService,
    private profileService: ProfileService) {
  }

  public ngOnInit(): void {
    this.data$ = combineLatest([this.userService.getCurrent(), this.profileService.getCurrentUserProfile()]).pipe(
      map(([ user, profile ]) => ({
        currentUser: user,
        currentUserProfile: profile
      })
    ));
  }
}

interface Data {
  currentUser: User;
  currentUserProfile: Profile;
}