import { LowerCasePipe } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { Role } from 'src/app/model/role';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-change-role-form',
  standalone: true,
  imports: [
    LowerCasePipe,
    ReactiveFormsModule
  ],
  templateUrl: './change-role-form.component.html'
})
export class ChangeRoleFormComponent {
  protected selectedUser: User | null = null;
  protected form = this.createForm(null);
  protected isBusy = false;
  protected errorMessage = '';

  @Input({ required: true })
  public users: User[] = [];

  public onUserChanged = output<User>();

  constructor(private userService: UserService) {
  }

  public onUserSelect(userId: string): void {
    if (userId) {
      const userToSelect = this.users.find(user => user.id === userId);
      if (userToSelect !== undefined) {
        this.selectedUser = userToSelect;
        this.form = this.createForm(userToSelect);
      }
    } else {
      this.selectedUser = null;
      this.form = this.createForm(null);
    }
  }

  public onFormSubmit(): void {
    if (this.selectedUser !== null && (this.form.dirty && this.form.valid)) {
      this.isBusy = true;
      this.errorMessage = '';
      const roles = [ Role.AUTHENTICATED ];

      if (this.form.value['isProfessional'] === true) {
        roles.push(Role.PROFESSIONAL);
      }
      if (this.form.value['isSales'] === true) {
        roles.push(Role.SALES);
      }
      if (this.form.value['isAdmin'] === true) {
        roles.push(Role.ADMIN);
      }

      this.selectedUser.roles = roles;

      this.userService
        .update(this.selectedUser)
        .subscribe({
          next: (user: User) => this.onUserChanged.emit(user),
          error: (err) => this.errorMessage = err.message,
          complete: () => this.isBusy = false
        });
    }
  }

  private createForm(user: User | null): FormGroup {
    return new FormGroup({
      isProfessional: new FormControl({ value: user?.isProfessional ?? false, disabled: user == null }),
      isSales: new FormControl({ value: user?.isSales ?? false, disabled: user == null }),
      isAdmin: new FormControl({ value: user?.isAdmin ?? false, disabled: user == null }),
    });
  }
}
