<div class="md:max-w-screen-lg md:mx-auto">
    <h1 class="text-xl font-bold mb-4">{{ title }}</h1>

    @if (data$ | async; as data) {
    @if (data == null) {
        <p i18n class="text-red-600">Unknown summary</p>
    } @else {
        <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
            <div>
                <label for="name" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Name</span>*</label>
                <input type="text" id="name" autocomplete="off" formControlName="name" class="input-txt" placeholder="" required />
                
                @if (data.fieldHasError('name', 'required')) {
                    <p i18n class="mt-2 text-sm text-red-600">The name is required.</p>
                }
            </div>
            <div #isSizeMd class="hidden collapse md:visible"></div>
            <div appAutogrowContainer [autogrowGroupedIfVisible]="isSizeMd" class="flex flex-col gap-4 md:flex-row">
                <div class="flex-1">
                    <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
                    <div class="rounded p-4 bg-gray-50 border border-gray-100">
                        <label for="nl" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Summary</span>*</label>
                        <textarea id="nl" formControlName="nl" data-autogrow rows="4" class="input-txt bg-white" placeholder="" required></textarea>
                        
                        @if (data.fieldHasError('nl', 'required')) {
                            <p i18n class="mt-2 text-sm text-red-600">The dutch summary is required.</p>
                        }
                    </div>
                </div>
                <div class="border border-gray-300 rounded my-3 hidden md:block"></div>
                <div class="flex-1">
                    <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
                    <div class="rounded p-4 bg-gray-50 border border-gray-100">
                        <label for="en" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Summary</span>*</label>
                        <textarea id="en" formControlName="en" data-autogrow rows="4" class="input-txt bg-white" placeholder="" required></textarea>
                        
                        @if (data.fieldHasError('en', 'required')) {
                            <p i18n class="mt-2 text-sm text-red-600">The english summary is required.</p>
                        }
                    </div>
                </div>
            </div>
            @if (errorMessage) {
                <p class="mt-4 text-red-600">{{ errorMessage }}</p>
            }
            <div class="mt-4 flex gap-4 flex-col md:flex-row-reverse">
                <button i18n type="submit" [disabled]="!data.canBeSaved" class="btn-primary">Save</button>
                <a i18n routerLink="../" class="btn-secondary">Cancel</a>
            </div>
        </form>
    }
    } @else {
        <div role="status" class="flex flex-col gap-4 animate-pulse">
            <div>
                <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            </div>
            <div class="flex flex-col gap-4 md:flex-row">
                <div class="flex-1">
                    <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
                    <div class="rounded p-4 bg-gray-50 border border-gray-100">
                    <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                    <div class="w-full h-[102px] bg-gray-300 rounded-xl"></div>
                    </div>
                </div>
                <div class="flex-1">
                    <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
                    <div class="rounded p-4 bg-gray-50 border border-gray-100">
                    <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                    <div class="w-full h-[102px] bg-gray-300 rounded-xl"></div>
                    </div>
                </div>
            </div>
            <span class="sr-only">Loading...</span>
        </div>
    }
</div>