<div class="md:max-w-screen-lg md:mx-auto">
    @if (items$ | async; as items) {
        <div class="mb-4 flex flex-row">
            <h1 class="text-xl font-bold flex-1">{{ listName | uppercaseFirstLetter }}</h1>
            <a i18n-title type="button" title="Create" routerLink="./create" class="text-gray-500 hover:text-gray-700">
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
            </a>
        </div>
        <ul class="flex flex-col" dndDropzone (dndDrop)="onDrop(items, $event)">
            <li class="py-5 bg-gray-50 border border-gray-200 rounded-lg" dndPlaceholderRef>
                <span class="font-medium collapse">Placeholder</span>
            </li>
            @for (item of items; track item.id) {
                <li class="py-5 border-b-slate-100" [class.border-b]="!$last" [dndDraggable]="item" dndEffectAllowed="move" dndDragImageRef>
                    <div class="flex flex-row items-center">
                        <div class="flex flex-col flex-1 md:flex-row">
                            <span class="flex-1 font-medium">{{ item.title }}</span>
                            <span class="flex-1 font-light text-gray-500">{{ item.subTitle | lowercase | uppercaseFirstLetter }}</span>
                        </div>
                        <div class="ml-auto flex gap-1">
                            <button i18n-title type="button" title="Delete" class="text-gray-300 hover:text-gray-600" (click)="modalDelete.open(item.id)">
                                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                                </svg>
                            </button>
                            <div dndHandle>
                                <button i18n-title type="button" title="Move" data-drag-trigger class="text-gray-300 hover:text-gray-600 cursor-move">
                                    <svg class="w-6 h-6" viewBox="0 0 20 20" fill="currentColor" x="128" y="128" role="img" style="display:inline-block;vertical-align:middle" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" d="M9.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L10.5 3.707V6.5a.5.5 0 0 1-1 0V3.707L8.354 4.854a.5.5 0 1 1-.708-.708l2-2ZM10 12a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm0-1a1 1 0 1 1 0-2a1 1 0 0 1 0 2Zm5.854 1.354l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.708.708L16.293 9.5H13.5a.5.5 0 0 0 0 1h2.793l-1.147 1.146a.5.5 0 0 0 .708.708Zm-5.5 5.5l2-2a.5.5 0 0 0-.708-.708L10.5 16.293V13.5a.5.5 0 0 0-1 0v2.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0Zm-8.208-7.5a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L3.707 9.5H6.5a.5.5 0 0 1 0 1H3.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2Z"/>
                                    </svg>
                                </button>
                            </div>
                            <a i18n-title title="Edit" [routerLink]="['./', item.id]" class="text-gray-300 hover:text-gray-600">
                                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </li>
            } @empty {
                <li class="py-5">
                    <span i18n>No {{ listName | lowercase }} available. Please add one using the plus icon at the top.</span>
                </li>
            }
        </ul>
    } @else {
        <h1 class="text-xl font-bold flex-1 mb-4">{{ listName | uppercaseFirstLetter }}</h1>
        <ul role="status" class="flex flex-col animate-pulse">
            @for (_ of [].constructor(3); track $index) {
                <li class="py-5 border-b-slate-100" [class.border-b]="!$last">
                    <div class="flex flex-row items-center">
                        <div>
                            <div class="h-6 w-36 md:w-64 bg-gray-300 rounded-full"></div>
                        </div>
                        <div class="ml-auto flex gap-1">
                            @for (_ of [].constructor(3); track $index) {
                                <div class="h-6 w-6 bg-gray-300 rounded-full"></div>
                            }
                        </div>
                    </div>
                </li>
            }
        </ul>
        <span class="sr-only">Loading...</span>
    }
    <app-modal-delete #modalDelete i18n-modalDeleteText modalDeleteText="Are you sure you want to delete this element?" (onDelete)="onDelete($event)"></app-modal-delete>
</div>
