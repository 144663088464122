import { Component, OnInit, ViewChild } from '@angular/core';
import { AssignmentFormComponent } from '../assignment-form/assignment-form.component';
import { DndDraggableDirective, DndDropEvent, DndDropzoneDirective, DndHandleDirective } from 'ngx-drag-drop';
import { DropdownDirective } from 'src/app/core/directives/dropdown.directive';
import { ModalDeleteComponent } from 'src/app/core/components/modal-delete/modal-delete.component';
import { RouterLink } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { UpperCaseFirstLetterPipe } from 'src/app/core/pipes/upper-case-first-letter.pipe';
import { ListService } from 'src/app/core/services/list.service';
import { ListItem } from 'src/app/profile/components/list/list-item.model';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [
    AssignmentFormComponent,
    AsyncPipe,
    DndDraggableDirective,
    DndDropzoneDirective,
    DndHandleDirective,
    DropdownDirective,
    LowerCasePipe,
    ModalDeleteComponent,
    RouterLink,
    NgTemplateOutlet,
    UpperCaseFirstLetterPipe
],
  templateUrl: './list.component.html'
})
export class ListComponent<T> implements OnInit {
  public errorMessage = '';

  @ViewChild('modalDelete')
  public modalDelete: ModalDeleteComponent<string>;

  public listName = '';
  public items$ = new ReplaySubject<ListItem<T>[]>(1);

  constructor(private listService: ListService<T>) {
    this.listName = listService.listName;
  }

  public ngOnInit(): void {
    this.listService
      .getItems()
      .subscribe({
        next: (items) => this.items$.next(items),
        error: (err) => this.errorMessage = err.message
      });
  }

  public onDelete(id: string): void {
    this.listService.delete(id).subscribe({
      next: (newItems) => {
        this.items$.next(newItems);
        this.modalDelete.close();
      },
      error: (err: HttpErrorResponse) => {
        this.modalDelete.errorMessage = err.message;
      }
    });
  }

  public onDrop(items: ListItem<T>[], event: DndDropEvent) {
    let orderChanged = false;

    if (event.dropEffect === 'move') {
      const currentIdx = items.findIndex(item => item.id == event.data.id);
      const newIdx = event.index ?? items.length;
      
      if (newIdx === currentIdx + 1 || newIdx === currentIdx + 1) {
        // The summary is dropped directly below or above the summary, so it does not need to move.
      } else if (newIdx < currentIdx) {
        items.splice(currentIdx, 1);
        items.splice(event.index, 0, event.data);
        orderChanged = true;
      } else if (currentIdx < newIdx) {
        items.splice(event.index, 0, event.data);
        items.splice(currentIdx, 1);
        orderChanged = true;
      }
    }

    if (orderChanged) {
      this.listService
        .changeOrder(items)
        .subscribe({
          next: (newItems) => {
            this.items$.next(newItems);
            this.errorMessage = '';
          },
          error: (err: HttpErrorResponse) => {
            this.errorMessage = err.message;
          }
        });
    }
  }
}
