<div class="md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold mb-4">{{ title }}</h1>

  @if (data$ | async; as data) {
    @if (data == null) {
      <p i18n class="text-red-600">Unknown education</p>
    } @else {
      <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
        <div class="flex flex-col gap-4 md:flex-row">
          <div class="flex-1">
              <label for="nameInstitute" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Institute name</span>*</label>
              <input type="text" id="nameInstitute" autocomplete="off" formControlName="nameInstitute" class="input-txt" placeholder="" required />
              
              @if (data.fieldHasError('nameInstitute', 'required')) {
                  <p i18n class="mt-2 text-sm text-red-600">The institute name is required.</p>
              }
          </div>
          <div class="flex-1">
            <label for="level" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Education level</span>*</label>
            <select id="level" formControlName="educationLevel" class="input-txt" required>
              @for (educationLevel of educationLevels; track educationLevel.key) {
                <option [value]="educationLevel.key">{{ educationLevel.name }}</option>
              }
            </select>
            
            @if (data.fieldHasError('educationLevel', 'required')) {
              <p i18n class="mt-2 text-sm text-red-600">The education level is required.</p>
            }
          </div>
        </div>
        <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                  <label for="nlName" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Education name</span>*</label>
                  <input type="text" id="nlName" autocomplete="off" formControlName="nlName" class="input-txt bg-white" placeholder="" required />
                  
                  @if (data.fieldHasError('nlName', 'required')) {
                      <p i18n class="mt-2 text-sm text-red-600">The dutch education name is required.</p>
                  }
                </div>
            </div>
            <div class="border border-gray-300 rounded my-3 hidden md:block"></div>
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                    <label for="enName" i18n class="block mb-2 text-sm font-semibold text-gray-900">Education name</label>
                    <input type="text" id="enName" autocomplete="off" formControlName="enName" class="input-txt bg-white" placeholder="" />
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-4 md:flex-row">
          <div class="flex-1">
              <label for="startDate" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Start date</span>*</label>
              <input type="text" id="startDate" autocomplete="off" appDatePicker formControlName="startDate" class="input-txt" placeholder="" required />
              
              @if (data.fieldHasError('startDate', 'required')) {
                  <p i18n class="mt-2 text-sm text-red-600">The start date is required.</p>
              }
          </div>
          <div class="flex-1">
              <label for="endDate" i18n class="block mb-2 text-sm font-semibold text-gray-900">End date</label>
              <input type="text" id="endDate" autocomplete="off" appDatePicker formControlName="endDate" class="input-txt" placeholder="" />
              
              @if (data.formHasError('dateBefore')) {
                <p i18n class="mt-2 text-sm text-red-600">The start date must be before the end date.</p>
              }
          </div>
        </div>
        <div>
          <input type="checkbox" name="hasGraduated" formControlName="hasGraduated" autocomplete="off" class="input-checkbox" placeholder="">
          <label for="hasGraduated" i18n class="ms-2 text-sm font-medium text-gray-900">Graduated</label>
        </div>
        @if (errorMessage) {
            <p class="mt-4 text-red-600">{{ errorMessage }}</p>
        }
        <div class="mt-4 flex gap-4 flex-col md:flex-row-reverse">
            <button i18n type="submit" [disabled]="!data.canBeSaved" class="btn-primary">Save</button>
            <a i18n routerLink="../" class="btn-secondary">Cancel</a>
        </div>
      </form>
    }
  } @else {
      <div role="status" class="flex flex-col gap-4 animate-pulse">
        <div class="flex flex-col gap-4 md:flex-row">
          <div class="flex-1">
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
          <div class="flex-1">
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
        </div>
        <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                  <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                  <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
                </div>
            </div>
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                  <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                  <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-4 md:flex-row">
          <div class="flex-1">
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
          <div class="flex-1">
              <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
              <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
          </div>
        </div>
        <div>
          <div class="w-40 h-6 bg-gray-300 rounded-xl"></div>
        </div>
        <span class="sr-only">Loading...</span>
      </div>
  }
</div>
