<div class="md:max-w-screen-lg md:mx-auto">
  <h1 class="text-xl font-bold mb-4">{{ title }}</h1>

  @if (data$ | async; as data) {
    @if (data == null) {
      <p i18n class="text-red-600">Unknown soft skill</p>
    } @else {
      <form [formGroup]="data.form" (ngSubmit)="onFormSubmit(data)" class="flex flex-col gap-4">
        <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                  <label for="nlName" class="block mb-2 text-sm font-semibold text-gray-900"><span i18n>Name</span>*</label>
                  <input type="text" id="nlName" autocomplete="off" formControlName="nlName" class="input-txt bg-white" placeholder="" required />
                  
                  @if (data.fieldHasError('nlName', 'required')) {
                      <p i18n class="mt-2 text-sm text-red-600">The dutch name is required.</p>
                  }
                </div>
            </div>
            <div class="border border-gray-300 rounded my-3 hidden md:block"></div>
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                    <label for="enName" i18n class="block mb-2 text-sm font-semibold text-gray-900">Name</label>
                    <input type="text" id="enName" autocomplete="off" formControlName="enName" class="input-txt bg-white" placeholder="" />
                </div>
            </div>
        </div>
        @if (errorMessage) {
            <p class="mt-4 text-red-600">{{ errorMessage }}</p>
        }
        <div class="mt-4 flex gap-4 flex-col md:flex-row-reverse">
            <button i18n type="submit" [disabled]="!data.canBeSaved" class="btn-primary">Save</button>
            <a i18n routerLink="../" class="btn-secondary">Cancel</a>
        </div>
      </form>
    }
  } @else {
      <div role="status" class="flex flex-col gap-4 animate-pulse">
        <div class="flex flex-col gap-4 md:flex-row">
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">Dutch</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                  <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                  <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
                </div>
            </div>
            <div class="flex-1">
                <h2 i18n class="mb-2 text-sm font-semibold text-gray-500">English</h2>
                <div class="rounded p-4 bg-gray-50 border border-gray-100">
                  <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                  <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
                </div>
            </div>
        </div>
        <span class="sr-only">Loading...</span>
      </div>
  }
</div>
