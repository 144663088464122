import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAutogrowContainer]',
  standalone: true
})
export class AutogrowContainerDirective implements AfterViewInit {
  private height: number;
  private children: { elem: HTMLElement, height: number }[] = [];

  @Input()
  public autogrowGroupedIfVisible: HTMLElement;

  constructor(private elem: ElementRef) {
  }

  public ngAfterViewInit(): void {
    this.findChildren(this.elem.nativeElement, this.children);
    this.height = this.calculateMaxHeight();

    for (let i = 0; i < this.children.length; i++) {
      this.children[i].elem.addEventListener('input', () => this.onInputChange(this.children[i]));
      this.children[i].elem.style.height = this.height + 'px';
    }
  }

  @HostListener('window:resize')
  private onResize(): void {
    for (let i = 0; i < this.children.length; i++) {
      this.onInputChange(this.children[i]);
    }
  }

  private onInputChange(child: { elem: HTMLElement, height: number }): void {
    child.height = this.calculateHeight(child.elem);

    const newMaxHeight = this.calculateMaxHeight();
    if (this.isVisible(this.autogrowGroupedIfVisible)) {
      if (this.height != newMaxHeight) {
        // The maximum height has changed. Change the height of all children.
        for (let i = 0; i < this.children.length; i++) {
          this.children[i].elem.style.height = newMaxHeight + 'px';
        }

        this.height = newMaxHeight;
      } else {
        // Set the height of this child again as the calculation of the height reset it to 'auto'.
        child.elem.style.height = this.height + 'px';
      }
    } else {
      // Set the height of this child again as the calculation of the height reset it to 'auto'.
      child.elem.style.height = child.height + 'px';
    }
  }

  private findChildren(elem: HTMLElement, children: { elem: HTMLElement, height: number }[]): void {
    if (elem.dataset.autogrow != null) {
      children.push({ elem : elem, height: this.calculateHeight(elem) });
      elem.style.overflow = 'hidden';
      elem.style.resize = 'none';
    }

    for (let i = 0; i < elem.children.length; i++) {
      this.findChildren(elem.children[i] as HTMLElement, children);
    }
  }

  private calculateHeight(elem: HTMLElement): number {
    const baseHeight = elem.offsetHeight - elem.clientHeight;

    // Set the height to auto such that the scrollHeight can be measured.
    elem.style.height = 'auto';

    // Calculate the new height.
    return elem.scrollHeight + baseHeight;
  }

  private calculateMaxHeight(): number {
    const heights = this.children.map(child => child.height);
    return Math.max(...heights);
  }

  private isVisible(elem: HTMLElement): boolean {
    return window.getComputedStyle(elem).visibility == 'visible';
  }
}
