<div class="flex flex-col gap-10 md:max-w-screen-lg md:mx-auto">
  @if (errorMessage) {
    <p class="mb-2 text-red-600">{{ errorMessage }}</p>
  }
  @if (users$ | async; as users) {
    <app-change-role-form [users]="users" (onUserChanged)="onUserUpdated(users, $event)"></app-change-role-form>
    <hr>
    <app-create-user-form (onUserCreated)="onUserCreated(users, $event)"></app-create-user-form>
    <hr>
    <app-delete-user-form [users]="users" (onUserDeleted)="onUserDeleted(users, $event)"></app-delete-user-form>
  } @else {
    <div role="status" class="space-y-3.5 animate-pulse flex flex-col gap-4 w-full">
        @for (_ of [].constructor(3); track $index) {
          <div class="flex flex-col gap-2">
            <div>
                <div class="w-36 h-7 mb-2 bg-gray-300 rounded-full"></div>
            </div>
            <div>
                <div class="w-24 h-5 mb-2 bg-gray-300 rounded-full"></div>
                <div class="w-full h-[41.6px] bg-gray-300 rounded-xl"></div>
            </div>
          </div>

          @if (!$last) {
            <hr>
          }
        }
        <span class="sr-only">Loading...</span>
    </div>
  }
</div>