import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ProFormData } from 'src/app/model/pro-form-data';
import { Profile } from 'src/app/model/profile';
import { Summary } from 'src/app/model/summary';
import { AutogrowContainerDirective } from 'src/app/core/directives/autogrow-container.directive';

@Component({
  selector: 'app-summary-form',
  standalone: true,
  imports: [
    AsyncPipe,
    AutogrowContainerDirective,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './summary-form.component.html'
})
export class SummaryFormComponent implements OnInit {
  @Input()
  public id: string;

  public title: string;
  public data$: Observable<ProFormData | null>;
  public errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ProfileService) {
  }

  public ngOnInit(): void {
    const isCreate = this.id?.trim()?.toLowerCase() == 'create';
    this.title = isCreate ? $localize`Create summary` : $localize`Edit summary`;

    this.data$ = this.api
      .getActiveProfile()
      .pipe(
        map((profile: Profile) => {
          if (isCreate) {
            return new ProFormData(profile.id, this.createForm(null));
          } else {
            const initialValue = profile.summaries.find(summary => summary.id === this.id);
            if (!initialValue) {
              return null;
            } else {
              return new ProFormData(profile.id, this.createForm(initialValue));
            }
          }
        })
      );
  }

  public onFormSubmit(data: ProFormData): void {
    if (data.canBeSaved) {
      this.api
        .addSummary(
          data.profileId,
          {
            id: data.getFormControlValue('id'),
            name: data.getFormControlValue('name'),
            summaryNL: data.getFormControlValue('nl'),
            summaryEN: data.getFormControlValue('en')
          })
        .subscribe({
          next: (_: Summary) => {
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          error: (err: HttpErrorResponse) => {
            this.errorMessage = err.message;
          }
        });
    }
  }

  private createForm(summary: Summary | undefined): FormGroup {
    return new FormGroup({
      id: new FormControl(summary?.id),
      name: new FormControl(summary?.name, Validators.required),
      nl: new FormControl(summary?.summaryNL, Validators.required),
      en: new FormControl(summary?.summaryEN, Validators.required)
    });
  }
}
